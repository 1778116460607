var render = function () {
  var _vm$milestoneBoardInf, _vm$milestoneBoardInf2, _vm$milestoneBoardInf3, _vm$milestoneBoardInf4, _vm$milestoneBoardInf5, _vm$milestoneBoardInf6, _vm$milestoneBoardInf7, _vm$milestoneBoardInf8, _vm$milestoneBoardInf9, _vm$milestoneBoardInf10, _vm$milestoneBoardInf11, _vm$milestoneBoardInf12, _vm$milestoneBoardInf15, _vm$milestoneBoardInf16, _vm$milestoneBoardInf17, _vm$milestoneBoardInf18, _vm$subTaskPhase, _vm$taskPhase;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "contentBody"
  }, [_vm.milestoneBoardInfo ? _c('div', [_c('b-card-actions', {
    staticClass: "mb-1",
    attrs: {
      "action-collapse": ""
    },
    on: {
      "click": _vm.updateScrollableHeight
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [[_c('div', {
    ref: "filterBody",
    staticClass: "header-container"
  }, [_c('div', [(_vm$milestoneBoardInf = _vm.milestoneBoardInfo) !== null && _vm$milestoneBoardInf !== void 0 && _vm$milestoneBoardInf.milestone_id ? [_c('b-breadcrumb', {
    staticClass: "breadcrumb-chevron pl-0"
  }, [_c('b-breadcrumb-item', {
    on: {
      "click": _vm.onShowDepartmentDetails
    }
  }, [_vm._v(" Dept: " + _vm._s((_vm$milestoneBoardInf2 = _vm.milestoneBoardInfo) === null || _vm$milestoneBoardInf2 === void 0 ? void 0 : (_vm$milestoneBoardInf3 = _vm$milestoneBoardInf2.department) === null || _vm$milestoneBoardInf3 === void 0 ? void 0 : (_vm$milestoneBoardInf4 = _vm$milestoneBoardInf3.data) === null || _vm$milestoneBoardInf4 === void 0 ? void 0 : _vm$milestoneBoardInf4.name) + " ")]), _c('b-breadcrumb-item', {
    on: {
      "click": _vm.onShowMilestones
    }
  }, [_vm._v(" Milestone: " + _vm._s((_vm$milestoneBoardInf5 = _vm.milestoneBoardInfo) === null || _vm$milestoneBoardInf5 === void 0 ? void 0 : (_vm$milestoneBoardInf6 = _vm$milestoneBoardInf5.milestone) === null || _vm$milestoneBoardInf6 === void 0 ? void 0 : (_vm$milestoneBoardInf7 = _vm$milestoneBoardInf6.data) === null || _vm$milestoneBoardInf7 === void 0 ? void 0 : _vm$milestoneBoardInf7.title))]), _c('b-breadcrumb-item', {
    attrs: {
      "active": ""
    },
    on: {
      "click": _vm.onShowBoardDetails
    }
  }, [_vm._v(" " + _vm._s((_vm$milestoneBoardInf8 = _vm.milestoneBoardInfo) === null || _vm$milestoneBoardInf8 === void 0 ? void 0 : _vm$milestoneBoardInf8.title))])], 1)] : [_c('b-breadcrumb', {
    staticClass: "breadcrumb-chevron pl-0"
  }, [_c('b-breadcrumb-item', {
    attrs: {
      "active": ""
    }
  }, [_c('b', [_vm._v(" " + _vm._s((_vm$milestoneBoardInf9 = _vm.milestoneBoardInfo) === null || _vm$milestoneBoardInf9 === void 0 ? void 0 : _vm$milestoneBoardInf9.title))])])], 1)]], 2), _c('div', [_vm._l((_vm$milestoneBoardInf10 = _vm.milestoneBoardInfo) === null || _vm$milestoneBoardInf10 === void 0 ? void 0 : (_vm$milestoneBoardInf11 = _vm$milestoneBoardInf10.team) === null || _vm$milestoneBoardInf11 === void 0 ? void 0 : _vm$milestoneBoardInf11.data, function (member, index) {
    return _c('span', {
      key: index
    }, [index <= 2 ? [_c('b-avatar', {
      staticStyle: {
        "margin": "1px"
      },
      attrs: {
        "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
        "size": "26"
      }
    })] : _vm._e()], 2);
  }), ((_vm$milestoneBoardInf12 = _vm.milestoneBoardInfo) === null || _vm$milestoneBoardInf12 === void 0 ? void 0 : _vm$milestoneBoardInf12.team_count) > 3 ? _c('span', [_c('b-link', {
    on: {
      "click": function click($event) {
        var _vm$milestoneBoardInf13, _vm$milestoneBoardInf14;
        return _vm.showAllAddedMemberModal((_vm$milestoneBoardInf13 = _vm.milestoneBoardInfo) === null || _vm$milestoneBoardInf13 === void 0 ? void 0 : (_vm$milestoneBoardInf14 = _vm$milestoneBoardInf13.team) === null || _vm$milestoneBoardInf14 === void 0 ? void 0 : _vm$milestoneBoardInf14.data);
      }
    }
  }, [_vm._v(" + " + _vm._s(((_vm$milestoneBoardInf15 = _vm.milestoneBoardInfo) === null || _vm$milestoneBoardInf15 === void 0 ? void 0 : _vm$milestoneBoardInf15.team_count) - 3) + " more ")])], 1) : _vm._e(), _c('b-button', {
    staticClass: "btn-icon rounded-circle",
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.showAddBoardMemberModal(_vm.milestoneBoardInfo);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserPlusIcon"
    }
  })], 1), _c('b-dropdown', {
    staticClass: "ml-2",
    attrs: {
      "variant": "link",
      "toggle-class": "text-decoration-none board-drop-down",
      "no-caret": "",
      "dropleft": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "text-body align-middle mr-25",
          attrs: {
            "icon": "MenuIcon",
            "size": "16"
          }
        })];
      },
      proxy: true
    }], null, false, 957257862)
  }, [+((_vm$milestoneBoardInf16 = _vm.milestoneBoardInfo) === null || _vm$milestoneBoardInf16 === void 0 ? void 0 : _vm$milestoneBoardInf16.sub_tasks_count) == 0 && +((_vm$milestoneBoardInf17 = _vm.milestoneBoardInfo) === null || _vm$milestoneBoardInf17 === void 0 ? void 0 : _vm$milestoneBoardInf17.tasks_count) == 0 ? [_c('b-dropdown-item', {
    on: {
      "click": _vm.onShowMileStoneModal
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UploadIcon"
    }
  }), _c('span', [_vm._v(" Import From ")])], 1)] : _vm._e(), [_c('b-dropdown-item', {
    on: {
      "click": _vm.onShowBacklog
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ListIcon"
    }
  }), _c('span', [_vm._v(" Backlog List")])], 1)], [_c('b-dropdown-item', {
    on: {
      "click": _vm.onShowPhases
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "GridIcon"
    }
  }), _c('span', [_vm._v(" Phase List")])], 1)], [_c('b-dropdown-item', {
    on: {
      "click": _vm.onShowPhaseDuration
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "WatchIcon"
    }
  }), _c('span', [_vm._v(" Phase Duration")])], 1)], [_c('b-dropdown-item', {
    on: {
      "click": _vm.onShowActivitylog
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SlidersIcon"
    }
  }), _c('span', [_vm._v(" Board History")])], 1)], [_c('b-dropdown-item', {
    on: {
      "click": _vm.goToTrash
    }
  }, [_c('feather-icon', {
    staticClass: "board-trash-icon",
    attrs: {
      "icon": "Trash2Icon"
    }
  }), _c('span', [_vm._v(" Board Trash")])], 1)]], 2), _c('b-sidebar', {
    attrs: {
      "id": "sidebar-right",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadows": ""
    }
  }, [_c('div', [_c('validation-observer', {
    ref: "taskEdit"
  }, [_c('b-form', {
    staticClass: "mr-1",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.taskEditForm($event);
      }
    }
  }, [_c('h4', [_vm._v(" " + _vm._s(_vm.filterGroupBy == "sub_task" ? "Edit Sub Task Information" : "Edit Task Information") + " ")]), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "md": "10",
      "lg": "10",
      "xs": "12"
    }
  }, [_c('div', {
    attrs: {
      "id": "component-breadcrumbs"
    }
  }, [_c('b-breadcrumb', {
    staticClass: "breadcrumb-chevron"
  }, [_c('b-breadcrumb-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v(" " + _vm._s((_vm$milestoneBoardInf18 = _vm.milestoneBoardInfo) === null || _vm$milestoneBoardInf18 === void 0 ? void 0 : _vm$milestoneBoardInf18.title) + " ")]), _vm.filterGroupBy == 'sub_task' ? _c('b-breadcrumb-item', [_vm._v(" " + _vm._s(_vm.breadCrumTaskTitle) + " ")]) : _vm._e(), _c('b-breadcrumb-item', {
    on: {
      "click": function click($event) {
        return _vm.onShowTask(_vm.breadCrumShortTitle);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.breadCrumTitle) + " - (" + _vm._s(_vm.breadCrumShortTitle) + ") ")]), _vm.filterGroupBy == 'sub_task' ? _c('div', {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CopyIcon"
    },
    on: {
      "click": function click($event) {
        return _vm.onCopySubTaskLink(_vm.subTaskId);
      }
    }
  })], 1) : _c('div', {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CopyIcon"
    },
    on: {
      "click": function click($event) {
        return _vm.onCopyTaskLink(_vm.breadCrumShortTitle);
      }
    }
  })], 1)], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "outline-primary",
      "text": _vm.filterGroupBy == 'sub_task' ? (_vm$subTaskPhase = _vm.subTaskPhase) === null || _vm$subTaskPhase === void 0 ? void 0 : _vm$subTaskPhase.title : (_vm$taskPhase = _vm.taskPhase) === null || _vm$taskPhase === void 0 ? void 0 : _vm$taskPhase.title,
      "size": "sm"
    }
  }, [_vm._l(_vm.taskPhaseOptions, function (phase) {
    return _c('b-dropdown-item', {
      key: phase.id,
      on: {
        "click": function click($event) {
          return _vm.updatePhase(phase);
        }
      }
    }, [_vm._v(" " + _vm._s(phase.title) + " ")]);
  }), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    on: {
      "click": _vm.onShowPhase
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusCircleIcon"
    }
  }), _vm._v(" Add More ")], 1)], 2)], 1)], 1), [_vm.filterGroupBy == 'sub_task' ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Title ",
      "label-for": "title"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "title",
      "vid": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter task title"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4147875367)
  })], 1)], 1)], 1) : _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "lg": "8",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Title ",
      "label-for": "title"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "title",
      "vid": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter task title"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4147875367)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type ",
      "label-for": "type"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "type",
      "vid": "type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "type",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter task type"
          },
          model: {
            value: _vm.type,
            callback: function callback($$v) {
              _vm.type = $$v;
            },
            expression: "type"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1004053375)
  })], 1)], 1)], 1), _vm.filterGroupBy == 'sub_task' ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type",
      "label-for": "priority"
    }
  }, [_c('b-form-input', {
    staticClass: "custom-font",
    attrs: {
      "id": "title",
      "type": "text",
      "value": +_vm.sequenceNumber ? 'Sequential' : 'General',
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Estimation Point",
      "label-for": "point"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "point",
      "vid": "point",
      "rules": "min_value:0|max_value:9999"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "point",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Estimation Point"
          },
          model: {
            value: _vm.point,
            callback: function callback($$v) {
              _vm.point = $$v;
            },
            expression: "point"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 923793085)
  })], 1)], 1)], 1) : _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Priority ",
      "label-for": "priority"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "priority",
      "vid": "priority"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "priority",
            "options": _vm.priorityOptionConstants,
            "reduce": function reduce(option) {
              return option.value;
            },
            "placeholder": "Select task priority",
            "label": "name"
          },
          model: {
            value: _vm.selectPriorityType,
            callback: function callback($$v) {
              _vm.selectPriorityType = $$v;
            },
            expression: "selectPriorityType"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2966992974)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Label",
      "label-for": "labels"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "labels",
      "vid": "labels"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "id": "labels",
            "placeholder": "Add Task Label",
            "label": "name",
            "multiple": "",
            "taggable": "",
            "push-tags": ""
          },
          model: {
            value: _vm.selectLabels,
            callback: function callback($$v) {
              _vm.selectLabels = $$v;
            },
            expression: "selectLabels"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1339819710)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Estimation Point",
      "label-for": "point"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "point",
      "vid": "point",
      "rules": "min_value:0|max_value:9999"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "point",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Estimation Point"
          },
          model: {
            value: _vm.point,
            callback: function callback($$v) {
              _vm.point = $$v;
            },
            expression: "point"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 923793085)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Observer",
      "label-for": "observer"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "observer",
      "vid": "observer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "observer",
            "options": _vm.observerOptions,
            "reduce": function reduce(item) {
              return item.id;
            },
            "placeholder": "Select Observer",
            "label": "name"
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectObserver,
            callback: function callback($$v) {
              _vm.selectObserver = $$v;
            },
            expression: "selectObserver"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 475376298)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Assigner",
      "label-for": "assigner"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "assigner",
      "vid": "assigner"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "assigner",
            "options": _vm.assigneeOptions,
            "reduce": function reduce(item) {
              return item.id;
            },
            "placeholder": "Select Assigner",
            "label": "name"
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectAssignee,
            callback: function callback($$v) {
              _vm.selectAssignee = $$v;
            },
            expression: "selectAssignee"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1115903754)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Assignee",
      "label-for": "user_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "user_id",
      "vid": "user_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user_id",
            "placeholder": "Search by employee name or email",
            "filterable": true,
            "options": _vm.assigneeOptions,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "multiple": ""
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectMemberIds,
            callback: function callback($$v) {
              _vm.selectMemberIds = $$v;
            },
            expression: "selectMemberIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 421338459)
  })], 1)], 1)], 1), _vm.previousFiles.length > 0 ? [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_c('div', {
    staticClass: "scrollable-file-container"
  }, [_c('div', {
    staticClass: "uploaded-files mb-2 scrollable-file-content"
  }, _vm._l(_vm.previousFiles, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "file-preview"
    }, [_c('b-row', {
      staticClass: "mr-1"
    }, [_c('b-col', {
      attrs: {
        "md": "8",
        "lg": "8",
        "xs": "12"
      }
    }, [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.bottom",
        value: item.file_name,
        expression: "\n                                              item.file_name\n                                            ",
        modifiers: {
          "hover": true,
          "bottom": true
        }
      }]
    }, [_vm.isPreviousFileImage(item) ? [_c('img', {
      attrs: {
        "src": item === null || item === void 0 ? void 0 : item.original_url,
        "alt": "Preview",
        "width": "100",
        "height": "100"
      }
    })] : [_c('svg', {
      attrs: {
        "width": "100px",
        "height": "100px",
        "viewBox": "0 0 24 24",
        "xmlns": "http://www.w3.org/2000/svg",
        "fill": "#000000"
      }
    }, [_c('g', {
      attrs: {
        "id": "SVGRepo_bgCarrier",
        "stroke-width": "0"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_tracerCarrier",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_iconCarrier"
      }
    }, [_c('title'), _c('g', {
      attrs: {
        "id": "Complete"
      }
    }, [_c('g', {
      attrs: {
        "id": "F-File"
      }
    }, [_c('g', {
      attrs: {
        "id": "Text"
      }
    }, [_c('g', [_c('path', {
      attrs: {
        "d": "M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z",
        "fill": "none",
        "id": "File",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "17.5",
        "y2": "17.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "13.5",
        "y2": "13.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "8",
        "x2": "13",
        "y1": "9.5",
        "y2": "9.5"
      }
    })])])])])])])]], 2)]), _c('b-col', {
      attrs: {
        "md": "3",
        "lg": "3",
        "xs": "12"
      }
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "\n                                              'rgba(255, 255, 255, 0.15)'\n                                            ",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "flex-shrink-0 btn-sm",
      attrs: {
        "variant": "outline-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removePreviousFile(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1), _c('br'), _c('br'), _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "\n                                              'rgba(255, 255, 255, 0.15)'\n                                            ",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "flex-shrink-0 btn-sm",
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.onShowImage(item);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "EyeIcon"
      }
    })], 1)], 1)], 1)], 1);
  }), 0)])])], 1)] : _vm._e(), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "file-input"
  }, [_c('label', {
    staticClass: "attach-icon",
    attrs: {
      "for": "file-upload"
    }
  }, [_vm._v(" Attach File "), _c('feather-icon', {
    staticClass: "mr-50",
    staticStyle: {
      "color": "#7367f0"
    },
    attrs: {
      "icon": "PaperclipIcon"
    }
  })], 1), _c('input', {
    attrs: {
      "hidden": "",
      "id": "file-upload",
      "type": "file",
      "multiple": ""
    },
    on: {
      "change": _vm.handleFileUpload
    }
  })])])], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "scrollable-file-container"
  }, [_c('div', {
    staticClass: "uploaded-files mb-2 scrollable-file-content"
  }, _vm._l(_vm.uploadedFiles, function (file, index) {
    return _c('div', {
      key: index,
      staticClass: "file-preview"
    }, [_c('b-row', {
      staticClass: "mr-1"
    }, [_c('b-col', {
      attrs: {
        "md": "8",
        "lg": "8",
        "xs": "12"
      }
    }, [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.bottom",
        value: file.name,
        expression: "file.name",
        modifiers: {
          "hover": true,
          "bottom": true
        }
      }]
    }, [_vm.isImage(file) ? [_c('img', {
      attrs: {
        "src": file.preview,
        "alt": "Preview",
        "width": "100",
        "height": "100"
      },
      on: {
        "click": function click($event) {
          return _vm.onShowImage(file);
        }
      }
    })] : [_c('svg', {
      attrs: {
        "width": "100px",
        "height": "100px",
        "viewBox": "0 0 24 24",
        "xmlns": "http://www.w3.org/2000/svg",
        "fill": "#000000"
      }
    }, [_c('g', {
      attrs: {
        "id": "SVGRepo_bgCarrier",
        "stroke-width": "0"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_tracerCarrier",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_iconCarrier"
      }
    }, [_c('title'), _c('g', {
      attrs: {
        "id": "Complete"
      }
    }, [_c('g', {
      attrs: {
        "id": "F-File"
      }
    }, [_c('g', {
      attrs: {
        "id": "Text"
      }
    }, [_c('g', [_c('path', {
      attrs: {
        "d": "M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z",
        "fill": "none",
        "id": "File",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "17.5",
        "y2": "17.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "13.5",
        "y2": "13.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "8",
        "x2": "13",
        "y1": "9.5",
        "y2": "9.5"
      }
    })])])])])])])]], 2)]), _c('b-col', {
      attrs: {
        "md": "3",
        "lg": "3",
        "xs": "12"
      }
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "\n                                            'rgba(255, 255, 255, 0.15)'\n                                          ",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "flex-shrink-0 btn-sm",
      attrs: {
        "variant": "outline-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFile(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1)], 1)], 1);
  }), 0)])])], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Description",
      "label-for": "description"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "description",
      "vid": "description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('quill-editor', {
          style: {
            height: '13rem !important',
            paddingBottom: '5rem !important'
          },
          attrs: {
            "options": _vm.editorOption
          },
          model: {
            value: _vm.description,
            callback: function callback($$v) {
              _vm.description = $$v;
            },
            expression: "description"
          }
        }, [_c('div', {
          attrs: {
            "slot": "toolbar",
            "id": "toolbar"
          },
          slot: "toolbar"
        }, [_c('span', {
          staticClass: "ql-formats"
        }, [_c('button', {
          staticClass: "ql-bold"
        }, [_vm._v("Bold")]), _c('button', {
          staticClass: "ql-italic"
        }, [_vm._v(" Italic ")]), _c('button', {
          staticClass: "ql-underline"
        }, [_vm._v(" Underline ")]), _c('button', {
          staticClass: "ql-strike"
        }, [_vm._v(" Strike ")]), _c('button', {
          staticClass: "ql-blockquote"
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "ordered"
          }
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "bullet"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "sub"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "super"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "-1"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "+1"
          }
        }), _c('button', {
          staticClass: "ql-direction",
          attrs: {
            "value": "rtl"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": ""
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "center"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "right"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "justify"
          }
        }), _c('select', {
          staticClass: "ql-color"
        }), _c('select', {
          staticClass: "ql-background"
        }), _c('select', {
          staticClass: "ql-size"
        }, [_c('option', {
          attrs: {
            "value": "small"
          }
        }), _c('option', {
          attrs: {
            "selected": ""
          }
        }), _c('option', {
          attrs: {
            "value": "large"
          }
        }), _c('option', {
          attrs: {
            "value": "huge"
          }
        })]), _c('select', {
          staticClass: "ql-font"
        }), _c('select', {
          staticClass: "ql-header"
        }, [_c('option', {
          attrs: {
            "value": "1"
          }
        }, [_vm._v("Heading 1")]), _c('option', {
          attrs: {
            "value": "2"
          }
        }, [_vm._v("Heading 2")]), _c('option', {
          attrs: {
            "value": "3"
          }
        }, [_vm._v("Heading 3")]), _c('option', {
          attrs: {
            "value": "4"
          }
        }, [_vm._v("Heading 4")]), _c('option', {
          attrs: {
            "value": "5"
          }
        }, [_vm._v("Heading 5")]), _c('option', {
          attrs: {
            "value": "6"
          }
        }, [_vm._v("Heading 6")]), _c('option', {
          attrs: {
            "selected": ""
          }
        }, [_vm._v("Normal")])]), _c('button', {
          staticClass: "ql-link"
        })])])]), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2983971404)
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "text-align-end",
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('h6', {
    on: {
      "click": _vm.toggleActualDatesShow
    }
  }, [_vm._v(" Actual Dates "), _vm.toggleActualDates ? _c('a', {
    staticStyle: {
      "color": "blue"
    }
  }, [_vm._v("Hide")]) : _c('a', {
    staticStyle: {
      "color": "blue"
    }
  }, [_vm._v("View")])])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Start Date",
      "label-for": "start_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "start_date",
      "vid": "start_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "start_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Select Start Date "
          },
          model: {
            value: _vm.startDate,
            callback: function callback($$v) {
              _vm.startDate = $$v;
            },
            expression: "startDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 830469533)
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Assigned End Date",
      "label-for": "assigned_end_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "assigned_end_date",
      "vid": "assigned_end_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "assigned_end_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Assigned End Date "
          },
          model: {
            value: _vm.assignedEndDate,
            callback: function callback($$v) {
              _vm.assignedEndDate = $$v;
            },
            expression: "assignedEndDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1580021413)
  })], 1)], 1), _vm.toggleActualDates ? _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Actual Start Date",
      "label-for": "actual_start_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "actual_start_date",
      "vid": "actual_start_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "actual_start_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Actual Start Date "
          },
          model: {
            value: _vm.actualStartDate,
            callback: function callback($$v) {
              _vm.actualStartDate = $$v;
            },
            expression: "actualStartDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 161280068)
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Actual End Date",
      "label-for": "actual_end_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "actual_end_date",
      "vid": "actual_end_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "actual_end_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Actual End Date "
          },
          model: {
            value: _vm.actualEndDate,
            callback: function callback($$v) {
              _vm.actualEndDate = $$v;
            },
            expression: "actualEndDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3339335275)
  })], 1)], 1) : _vm._e()], 1), _c('b-row', [_c('b-col', [_vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_EDIT, _vm.permissions) && _vm.filterGroupBy != 'sub_task' ? [_vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right mt-50",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Save Task ")])]] : _vm._e()], 2)], 1), _vm.$permissionAbility(_vm.MILESTONE_BOARD_SUB_TASK_CREATE, _vm.permissions) ? [_vm.filterGroupBy != 'sub_task' ? _c('b-row', {
    staticClass: "mt-1 mb-1"
  }, [_c('b-col', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn btn-sm",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.showSubTaskForm
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "BriefcaseIcon"
    }
  }), _vm._v(" Add Sub Task ")], 1)], 1)], 1) : _vm._e()] : _vm._e(), _vm.isSubTaskFormLoaded ? [_c('b-row', {
    staticClass: "mt-1 mb-1"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('validation-observer', {
    ref: "subTaskAdd"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.subTaskAddForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "lg": "8",
      "xs": "12"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "title",
      "vid": "title"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Sub Task Title"
          },
          model: {
            value: _vm.subTaskTitle,
            callback: function callback($$v) {
              _vm.subTaskTitle = $$v;
            },
            expression: "subTaskTitle"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1186396778)
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_vm.isSubTaskLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "\n                                              'rgba(255, 255, 255, 0.15)'\n                                            ",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right btn-sm",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.closeSubTaskForm
    }
  }, [_vm._v(" Cancel ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "\n                                              'rgba(255, 255, 255, 0.15)'\n                                            ",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right mr-1 btn-sm",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Add ")])]], 2)], 1)], 1)], 1)], 1)], 1)] : _vm._e(), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-list-group', _vm._l(_vm.subTaskList, function (subTask) {
    var _subTask$phase, _subTask$phase$data;
    return _c('b-list-group-item', {
      key: subTask === null || subTask === void 0 ? void 0 : subTask.id,
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('b-link', {
      on: {
        "click": function click($event) {
          return _vm.onShowSubTask(subTask === null || subTask === void 0 ? void 0 : subTask.id);
        }
      }
    }, [_vm._v(_vm._s(subTask === null || subTask === void 0 ? void 0 : subTask.title))]), _c('div', {
      staticClass: "custom-search d-flex align-items-center justify-content-end"
    }, [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(_vm._s(subTask === null || subTask === void 0 ? void 0 : (_subTask$phase = subTask.phase) === null || _subTask$phase === void 0 ? void 0 : (_subTask$phase$data = _subTask$phase.data) === null || _subTask$phase$data === void 0 ? void 0 : _subTask$phase$data.title))]), _vm.$permissionAbility(_vm.MILESTONE_BOARD_SUB_TASK_DELETE, _vm.permissions) ? [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "\n                                          'rgba(255, 255, 255, 0.15)'\n                                        ",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "btn-sm",
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteSubTask(subTask === null || subTask === void 0 ? void 0 : subTask.id);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "TrashIcon"
      }
    })], 1)] : _vm._e()], 2)], 1);
  }), 1)], 1)], 1)], _vm.$permissionAbility(_vm.MILESTONE_BOARD_SUB_TASK_EDIT, _vm.permissions) && _vm.filterGroupBy == 'sub_task' ? [_vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right mt-2 mb-2",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Save Sub Task ")])]] : _vm._e()], 2)], 1)], 1)])], 2)])]], 2)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.groupByOptions,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "Group By"
    },
    on: {
      "input": _vm.filterByGroup
    },
    model: {
      value: _vm.filterGroupBy,
      callback: function callback($$v) {
        _vm.filterGroupBy = $$v;
      },
      expression: "filterGroupBy"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-input', {
    staticClass: "custom-font",
    attrs: {
      "placeholder": "Search By Title",
      "type": "text"
    },
    on: {
      "keyup": _vm.filter
    },
    model: {
      value: _vm.filterTitle,
      callback: function callback($$v) {
        _vm.filterTitle = $$v;
      },
      expression: "filterTitle"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.taskStatusOptions,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "Status"
    },
    on: {
      "input": _vm.filter
    },
    model: {
      value: _vm.filterStatus,
      callback: function callback($$v) {
        _vm.filterStatus = $$v;
      },
      expression: "filterStatus"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "placeholder": "Assinged Users",
      "options": _vm.filterMemberIdOptions,
      "reduce": function reduce(country) {
        return country.id;
      },
      "label": "name"
    },
    on: {
      "input": _vm.filter
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }], null, false, 3839851139),
    model: {
      value: _vm.filterAssignedUserId,
      callback: function callback($$v) {
        _vm.filterAssignedUserId = $$v;
      },
      expression: "filterAssignedUserId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Filter Actual Date Range",
      "config": {
        mode: 'range'
      }
    },
    on: {
      "input": _vm.filter
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.isMilestoneTaskBoardLoading ? _c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    staticClass: "mr-1",
    staticStyle: {
      "width": "3rem",
      "height": "3rem"
    },
    attrs: {
      "variant": "primary"
    }
  })], 1) : _c('div', {
    staticClass: "scroll-container",
    style: {
      'maxHeight': _vm.scrollableTaskHeight
    }
  }, [_vm.phaseList.length > 0 ? _c('div', {
    staticClass: "scrollable-main-container"
  }, [_c('draggable', {
    staticClass: "phase-list-draggable",
    attrs: {
      "list": _vm.phaseList,
      "tag": "ul",
      "options": {
        direction: 'horizontal',
        handle: '.phase-card-title'
      }
    },
    on: {
      "change": _vm.onPhaseSequenceChange
    }
  }, _vm._l(_vm.phaseList, function (item, index) {
    var _item$subTasks;
    return _c('b-list-group-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "tag": "li"
      }
    }, [[_c('b-card', {
      staticClass: "phase-list-main-card phase-container",
      attrs: {
        "data-phase-id": item.id
      }
    }, [_c('b-card-sub-title', {
      staticClass: "mb-1 phase-card-title"
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "cols": "9"
      }
    }, [_c('span', {
      staticClass: "ml-1 custom-font-color mr-1"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.title))]), !(item !== null && item !== void 0 && item.is_backlog) ? [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": item !== null && item !== void 0 && item.is_final ? 'light-danger' : 'light-primary'
      }
    }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.is_final ? "Final" : "General") + " ")])] : item !== null && item !== void 0 && item.is_backlog ? [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "light-info"
      }
    }, [_vm._v("Initial")])] : _vm._e()], 2), !(item !== null && item !== void 0 && item.is_backlog) ? _c('b-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "btn-sm",
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.onShowPhaseModal(item);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "Edit2Icon"
      }
    })], 1)], 1) : _c('b-col', {
      attrs: {
        "cols": "3"
      }
    }, [_vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_CREATE, _vm.permissions) ? [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "btn-sm",
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.addTaskInBacklogModal(item);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusIcon"
      }
    })], 1)] : _vm._e()], 2)], 1)], 1), _vm.filterGroupBy == 'sub_task' ? _vm._l(item === null || item === void 0 ? void 0 : (_item$subTasks = item.subTasks) === null || _item$subTasks === void 0 ? void 0 : _item$subTasks.data, function (subTask) {
      var _subTask$task, _subTask$task$data, _subTask$task2, _subTask$task2$data, _subTask$team, _subTask$team2, _subTask$team3;
      return _c('b-list-group', {
        key: subTask.id
      }, [(subTask === null || subTask === void 0 ? void 0 : subTask.phase_id) == (item === null || item === void 0 ? void 0 : item.id) ? _c('div', [_c('b-list-group-item', {
        staticClass: "p-0"
      }, [_c('b-card', {
        staticClass: "task-sub-task-items"
      }, [_c('b-card-sub-title', {
        staticClass: "custom-font"
      }, [_c('b-row', [_c('b-col', {
        attrs: {
          "cols": "8"
        }
      }, [_c('div', {
        directives: [{
          name: "b-toggle",
          rawName: "v-b-toggle.sidebar-right",
          modifiers: {
            "sidebar-right": true
          }
        }],
        on: {
          "click": function click($event) {
            return _vm.onShowTaskSidebar(subTask);
          }
        }
      }, [_c('span', {
        staticClass: "custom-font-color",
        attrs: {
          "title": subTask === null || subTask === void 0 ? void 0 : subTask.title
        }
      }, [_vm._v(" " + _vm._s(_vm.truncateTitle(subTask === null || subTask === void 0 ? void 0 : subTask.title)))])]), _c('div', {
        staticClass: "mt-1 mb-1",
        attrs: {
          "title": subTask === null || subTask === void 0 ? void 0 : (_subTask$task = subTask.task) === null || _subTask$task === void 0 ? void 0 : (_subTask$task$data = _subTask$task.data) === null || _subTask$task$data === void 0 ? void 0 : _subTask$task$data.title
        }
      }, [_c('b-badge', {
        attrs: {
          "variant": "light-warning"
        }
      }, [_vm._v(_vm._s(_vm.truncateTitle(subTask === null || subTask === void 0 ? void 0 : (_subTask$task2 = subTask.task) === null || _subTask$task2 === void 0 ? void 0 : (_subTask$task2$data = _subTask$task2.data) === null || _subTask$task2$data === void 0 ? void 0 : _subTask$task2$data.title)))])], 1)]), _c('b-col', {
        attrs: {
          "cols": "4"
        }
      }, [_c('div', [_c('b-dropdown', {
        attrs: {
          "variant": "link",
          "no-caret": "",
          "id": "sub-task-d-down-".concat(subTask === null || subTask === void 0 ? void 0 : subTask.id)
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function fn() {
            return [_c('feather-icon', {
              staticClass: "text-body align-middle mr-25",
              attrs: {
                "icon": "MoreVerticalIcon",
                "size": "16"
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [[index != _vm.phaseList.length - 1 ? _c('b-dropdown-item', {
        on: {
          "click": function click($event) {
            return _vm.moveSubTaskToNextPhase(subTask);
          }
        }
      }, [_c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "ArrowRightCircleIcon"
        }
      }), _c('span', [_vm._v("Next Phase")])], 1) : _vm._e()], item !== null && item !== void 0 && item.is_final ? [_vm.$permissionAbility(_vm.MILESTONE_BOARD_UPDATE_TASK_FINAL_PHASE, _vm.permissions) ? [index != 0 ? _c('b-dropdown-item', {
        on: {
          "click": function click($event) {
            return _vm.moveSubTaskToPreviousPhase(subTask);
          }
        }
      }, [_c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "ArrowLeftCircleIcon"
        }
      }), _c('span', [_vm._v("Previous Phase")])], 1) : _vm._e()] : _vm._e()] : [index != 0 ? _c('b-dropdown-item', {
        on: {
          "click": function click($event) {
            return _vm.moveSubTaskToPreviousPhase(subTask);
          }
        }
      }, [_c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "ArrowLeftCircleIcon"
        }
      }), _c('span', [_vm._v("Previous Phase")])], 1) : _vm._e()]], 2)], 1)])], 1), _c('div', [_vm._l(subTask === null || subTask === void 0 ? void 0 : (_subTask$team = subTask.team) === null || _subTask$team === void 0 ? void 0 : _subTask$team.data, function (member, index) {
        return _c('span', {
          key: index
        }, [index <= 2 ? [_c('b-avatar', {
          attrs: {
            "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
            "size": "26"
          }
        })] : _vm._e()], 2);
      }), (subTask === null || subTask === void 0 ? void 0 : (_subTask$team2 = subTask.team) === null || _subTask$team2 === void 0 ? void 0 : _subTask$team2.data.length) > 3 ? _c('span', [_vm._v(" + " + _vm._s((subTask === null || subTask === void 0 ? void 0 : (_subTask$team3 = subTask.team) === null || _subTask$team3 === void 0 ? void 0 : _subTask$team3.data.length) - 3) + " more ")]) : _vm._e(), _c('b-button', {
        staticClass: "btn-icon rounded-circle btn-sm ml-1",
        attrs: {
          "variant": "outline-primary"
        },
        on: {
          "click": function click($event) {
            return _vm.showAddMemberModal(subTask);
          }
        }
      }, [_c('feather-icon', {
        attrs: {
          "icon": "UserPlusIcon"
        }
      })], 1)], 2)], 1)], 1)], 1)], 1) : _vm._e()]);
    }) : [_c('div', {
      staticClass: "scrollable-task",
      style: {
        maxHeight: _vm.scrollableTaskHeight
      }
    }, [_c('draggable', {
      attrs: {
        "list": item.tasks.data,
        "group": "tasks",
        "options": {
          group: {
            name: 'tasks',
            pull: true,
            put: true
          },
          handle: '.task-handle'
        }
      },
      on: {
        "change": _vm.onTaskMoved
      }
    }, _vm._l(item.tasks.data, function (task) {
      var _task$observer, _task$observer$data, _task$observer2, _task$observer2$data, _task$observer3, _task$observer3$data, _task$observer4, _task$observer4$data, _task$team, _task$team2, _task$team3;
      return _c('b-list-group-item', {
        key: task.id,
        staticClass: "p-0"
      }, [_c('b-card', {
        staticClass: "task-sub-task-items"
      }, [_c('b-card-sub-title', {
        ref: "taskCard",
        refInFor: true,
        staticClass: "custom-font task-handle",
        attrs: {
          "data-task-id": task.id
        }
      }, [_c('b-row', [_c('b-col', {
        attrs: {
          "cols": "12"
        }
      }, [_c('div', {
        directives: [{
          name: "b-toggle",
          rawName: "v-b-toggle.sidebar-right",
          modifiers: {
            "sidebar-right": true
          }
        }],
        on: {
          "click": function click($event) {
            return _vm.onShowTaskSidebar(task);
          }
        }
      }, [_c('span', {
        staticClass: "custom-font-color",
        attrs: {
          "title": task === null || task === void 0 ? void 0 : task.title
        }
      }, [_vm._v(" " + _vm._s(_vm.truncateTitle(task === null || task === void 0 ? void 0 : task.title)) + " ")]), task !== null && task !== void 0 && task.observer ? _c('span', {
        staticClass: "ml-1"
      }, [_c('b-avatar', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.top",
          value: "Observer: ".concat(task === null || task === void 0 ? void 0 : (_task$observer = task.observer) === null || _task$observer === void 0 ? void 0 : (_task$observer$data = _task$observer.data) === null || _task$observer$data === void 0 ? void 0 : _task$observer$data.name, " (").concat(task === null || task === void 0 ? void 0 : (_task$observer2 = task.observer) === null || _task$observer2 === void 0 ? void 0 : (_task$observer2$data = _task$observer2.data) === null || _task$observer2$data === void 0 ? void 0 : _task$observer2$data.email, ")"),
          expression: "\n                                        `Observer: ${task?.observer?.data?.name} (${task?.observer?.data?.email})`\n                                      ",
          modifiers: {
            "hover": true,
            "top": true
          }
        }],
        attrs: {
          "src": (task === null || task === void 0 ? void 0 : (_task$observer3 = task.observer) === null || _task$observer3 === void 0 ? void 0 : (_task$observer3$data = _task$observer3.data) === null || _task$observer3$data === void 0 ? void 0 : _task$observer3$data.avatar) === '' ? '/avatar.svg' : task === null || task === void 0 ? void 0 : (_task$observer4 = task.observer) === null || _task$observer4 === void 0 ? void 0 : (_task$observer4$data = _task$observer4.data) === null || _task$observer4$data === void 0 ? void 0 : _task$observer4$data.avatar,
          "size": "26"
        }
      })], 1) : _vm._e()])])], 1), _c('b-row', [_c('b-col', {
        attrs: {
          "cols": "9"
        }
      }, [_c('div', {
        staticClass: "mt-1"
      }, [_vm._l(task === null || task === void 0 ? void 0 : (_task$team = task.team) === null || _task$team === void 0 ? void 0 : _task$team.data, function (member, index) {
        return _c('span', {
          key: index
        }, [index <= 2 ? [_c('b-avatar', {
          attrs: {
            "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
            "size": "26"
          }
        })] : _vm._e()], 2);
      }), (task === null || task === void 0 ? void 0 : (_task$team2 = task.team) === null || _task$team2 === void 0 ? void 0 : _task$team2.data.length) > 3 ? _c('span', [_vm._v(" + " + _vm._s((task === null || task === void 0 ? void 0 : (_task$team3 = task.team) === null || _task$team3 === void 0 ? void 0 : _task$team3.data.length) - 3) + " more ")]) : _vm._e(), _c('b-button', {
        staticClass: "btn-icon rounded-circle btn-sm ml-1",
        attrs: {
          "variant": "outline-primary"
        },
        on: {
          "click": function click($event) {
            return _vm.showAddMemberModal(task);
          }
        }
      }, [_c('feather-icon', {
        attrs: {
          "icon": "UserPlusIcon"
        }
      })], 1)], 2)]), _c('b-col', {
        attrs: {
          "cols": "3"
        }
      }, [_c('div', [_c('b-dropdown', {
        ref: "task-d-down-".concat(task === null || task === void 0 ? void 0 : task.id),
        refInFor: true,
        attrs: {
          "variant": "link",
          "no-caret": "",
          "id": "task-d-down-".concat(task === null || task === void 0 ? void 0 : task.id)
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function fn() {
            return [_c('feather-icon', {
              staticClass: "text-body align-middle mr-25 mt-1",
              attrs: {
                "icon": "MoreVerticalIcon",
                "size": "16"
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [[index != _vm.phaseList.length - 1 ? _c('b-dropdown-item', {
        on: {
          "click": function click($event) {
            return _vm.moveTaskToNextPhase(task);
          }
        }
      }, [_c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "ArrowRightCircleIcon"
        }
      }), _c('span', [_vm._v("Next Phase")])], 1) : _vm._e()], [item !== null && item !== void 0 && item.is_final ? [_vm.$permissionAbility(_vm.MILESTONE_BOARD_UPDATE_TASK_FINAL_PHASE, _vm.permissions) ? [index != 0 ? _c('b-dropdown-item', {
        on: {
          "click": function click($event) {
            return _vm.moveTaskToPreviousPhase(task);
          }
        }
      }, [_c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "ArrowLeftCircleIcon"
        }
      }), _c('span', [_vm._v("Previous Phase")])], 1) : _vm._e()] : _vm._e()] : [index != 0 ? _c('b-dropdown-item', {
        on: {
          "click": function click($event) {
            return _vm.moveTaskToPreviousPhase(task);
          }
        }
      }, [_c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "ArrowLeftCircleIcon"
        }
      }), _c('span', [_vm._v("Previous Phase")])], 1) : _vm._e()]], _vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_DELETE, _vm.permissions) ? [_c('b-dropdown-item', {
        on: {
          "click": function click($event) {
            return _vm.onDeleteTask(task === null || task === void 0 ? void 0 : task.id);
          }
        }
      }, [_c('feather-icon', {
        staticClass: "mr-50 board-trash-icon",
        attrs: {
          "icon": "Trash2Icon"
        }
      }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e()], 2)], 1)])], 1)], 1)], 1)], 1);
    }), 1)], 1)]], 2)]], 2);
  }), 1), _c('div', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": _vm.showPhaseModal
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  })], 1)], 1)], 1) : _vm._e()]), _c('b-modal', {
    attrs: {
      "id": "modal-task-board-import",
      "centered": "",
      "title": "Import From Another Board",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hideMileStoneModal
    }
  }, [_c('validation-observer', {
    ref: "milestoneTaskBoardImport"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.milestoneTaskBoardImportForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Select Board *",
      "label-for": "milestone_task_board"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "milestone task board",
      "vid": "milestone_task_board_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('v-select', {
          attrs: {
            "id": "milestone_task_board_id",
            "placeholder": "Choose A Board",
            "options": _vm.milestoneBoardIdOption,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectMilestoneBoardId,
            callback: function callback($$v) {
              _vm.selectMilestoneBoardId = $$v;
            },
            expression: "selectMilestoneBoardId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-task-board-phase",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit Phase Information' : 'Add Phase',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenPhaseModal
    }
  }, [_c('validation-observer', {
    ref: "milestoneTaskBoardPhase"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.milestoneTaskBoardPhaseForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title *",
      "label-for": "title"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "title",
      "vid": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Phase Title"
          },
          model: {
            value: _vm.phaseTitle,
            callback: function callback($$v) {
              _vm.phaseTitle = $$v;
            },
            expression: "phaseTitle"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Type *",
      "label-for": "type"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "is_final",
      "vid": "is_final",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var errors = _ref19.errors;
        return [_c('v-select', {
          attrs: {
            "id": "is_final",
            "placeholder": "Choose a Type",
            "options": _vm.typeValueOption,
            "reduce": function reduce(country) {
              return country.value;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectTypeValue,
            callback: function callback($$v) {
              _vm.selectTypeValue = $$v;
            },
            expression: "selectTypeValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-task-board-task",
      "centered": "",
      "title": "Add Task",
      "hide-footer": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenTaskModal
    }
  }, [_c('validation-observer', {
    ref: "backlogTask"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.backlogTaskForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title *",
      "label-for": "title"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "title",
      "vid": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref20) {
        var errors = _ref20.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter task title"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-add-member-form",
      "centered": "",
      "title": "Assign Task To Member",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenAddMemberModal
    }
  }, [_c('validation-observer', {
    ref: "addMemberValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.addMemberValidationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Member",
      "label-for": "user_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "user_id",
      "vid": "user_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref21) {
        var errors = _ref21.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user_id",
            "placeholder": "Search by employee name or email",
            "filterable": true,
            "options": _vm.assigneeOptions,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "multiple": ""
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectMemberIds,
            callback: function callback($$v) {
              _vm.selectMemberIds = $$v;
            },
            expression: "selectMemberIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Add ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-add-board-member-form",
      "centered": "",
      "title": "Add Board Member",
      "hide-footer": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenAddBoardMemberModal
    }
  }, [_c('validation-observer', {
    ref: "addMemberValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.addBoardMemberValidationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Member",
      "label-for": "user_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "user_id",
      "vid": "user_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref22) {
        var errors = _ref22.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user_id",
            "placeholder": "Search by employee name or email",
            "options": _vm.memberIdOptions,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "multiple": ""
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectMemberIds,
            callback: function callback($$v) {
              _vm.selectMemberIds = $$v;
            },
            expression: "selectMemberIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Add ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-all-added-member-form",
      "centered": "",
      "title": "Assigned Members",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenAllAddedMemberModal
    }
  }, [_c('ul', _vm._l(_vm.assignedMember, function (member, index) {
    return _c('li', {
      key: index
    }, [_c('b-avatar', {
      staticStyle: {
        "margin": "1px"
      },
      attrs: {
        "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
        "size": "26"
      }
    }), _vm._v(" " + _vm._s(member.name) + " ")], 1);
  }), 0)]), _c('div', [_c('b-modal', {
    attrs: {
      "id": "file-preview-modal",
      "centered": "",
      "title": "File Preview",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenFilePreviewModal
    }
  }, [[_c('b-img', {
    attrs: {
      "src": _vm.previewFile,
      "fluid-grow": "",
      "alt": "Fluid-grow image"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v(" Open in new "), _c('b-link', {
    attrs: {
      "href": _vm.previewFile,
      "target": "_blank"
    }
  }, [_vm._v("window")])], 1)]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }